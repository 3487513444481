import { logger } from "../services/monitoring";

export const addQueryParams = (url, params) => {
  if (!params) return url;

  const u = new URL(url);

  Object.keys(params).forEach((key) => u.searchParams.append(key, params[key]));
  return u.toString();
};

export const errorHandler = (e) => {
  logger.error(e.stack);
  return {
    error: e.name,
    message: e.message,
  };
};

export const fetchRespHandler = (response) => {
  if (response.ok) return response.json();

  return response
    .text()
    .then((text) => {
      if (response.status >= 500) logger.error(text);
      return JSON.parse(text);
    })
    .catch((e) => {
      logger.error(e.stack);
      return {
        error: response.statusText,
        message: `${response.status} ${response.statusText}`,
      };
    });
};

export const getAuthHeaderOptions = (token) => ({
  Authorization: `Bearer ${token}`,
});
