import React from "react";
import { Snackbar } from "@material-ui/core";
import { useStatusContext } from "./Contexts/StatusContext";
import { useUserContext } from "./Contexts/UserContext";

export const MessageBar = () => {
  const { message, setMessage } = useStatusContext();
  const { error, setError } = useUserContext();
  const open = message !== null || error !== null;
  const msg = message || error;

  return (
    <Snackbar
      open={open}
      message={msg}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={10000}
      onClose={() => {
        setMessage(null);
        setError(null);
      }}
    />
  );
};
