const workerscript = () => {
  var INTERVAL = 60000;
  // eslint-disable-next-line no-restricted-globals
  var me = self;
  var timerID = 0;
  var deadline;
  
  function startTimer(duration) {
    deadline = new Date(Date.now() + duration - INTERVAL);
    stopTimer();
    timerID = setInterval(function() {
      var now = new Date();
      if (now > deadline) {
        me.postMessage("timeout");
        stopTimer();
      }
    }, INTERVAL);
  }

  function stopTimer() {
    if (timerID > 0) clearInterval(timerID);
    timerID = 0;
  }

  me.onmessage = function(e) {
    if (e.data.action === "start") {
      startTimer(e.data.duration);
    }
    else if (e.data.action === "stop") {
      stopTimer();
    }
  }
};

let code = workerscript.toString();
code = code.substring(code.indexOf("{")+1, code.lastIndexOf("}"));

const blob = new Blob([code], {type: "application/javascript"});
const timeout_script = URL.createObjectURL(blob);

module.exports = timeout_script;
