import React, { useState } from "react";
import clsx from "clsx";
import {
  Container,
  Drawer,
  AppBar,
  Toolbar,
  Typography,
  Divider,
  IconButton,
} from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";
import SideMenu from "./SideMenu";
import useStyles from "../useStyles";
import { useUserContext } from "../Contexts/UserContext";
import { useStatusContext } from "../Contexts/StatusContext";
import UserMenu from "./UserMenu";
import SiteBreadcrumbs from "../Controls/SiteBreadCrumbs";
import logo from "../../assets/fisker-badge.svg";

export default function MenuDrawer({ children }) {
  const classes = useStyles();
  const { title, sitePath } = useStatusContext();
  const { token } = useUserContext();

  const [drawerOpen, setDrawerOpen] = useState(true);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: token !== null && drawerOpen,
        })}
      >
        <Toolbar>
          {token !== null && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="toggle drawer"
              onClick={toggleDrawer}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          )}
          <div>
            <Typography variant="h6" noWrap>
              {title}
            </Typography>
            <SiteBreadcrumbs
              path={sitePath}
              className={classes.breadcrumbs}
            />
          </div>
          {token !== null && (
            <UserMenu color="inherit" className={classes.rightToolbar} />
          )}
        </Toolbar>
      </AppBar>
      {token !== null && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={drawerOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={clsx(classes.drawerHeader, classes.drawerHeaderLogo)}>
            <img
              src={logo}
              alt="Fisker Admin Portal"
              className={classes.logo}
            />
          </div>
          <Divider />
          <SideMenu />
        </Drawer>
      )}
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: token !== null && drawerOpen,
        })}
      >
        <div className={classes.drawerHeader} />
        <Container disableGutters={true} maxWidth={false} component="main">{children}</Container>
      </main>
    </div>
  );
}
