import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "@material-ui/core";

const SiteBreadcrumbs = ({ path }) => {
  if (!path || path.length === 0) return null;

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      color="inherit"
      style={{ fontSize: "10px" }}
    >
      {path.map((item, index, items) => {
        if (index < items.length) {
          return (
            <Link
              key={index}
              color="inherit"
              to={item.link || "#"}
              component={RouterLink}
            >
              {item.label}
            </Link>
          );
        }
        return null;
      })}
    </Breadcrumbs>
  );
};

export default SiteBreadcrumbs;
