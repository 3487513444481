import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

import {
  CANSignalProvider,
  useCANSignalContext,
} from "../../Contexts/CANSignalsContext";

const Main = ({ vin }) => {
  const { signals, setVIN, queryDate, delays, delayIndex, setDelayIndex } = useCANSignalContext();

  useEffect(() => {
    setVIN(vin);
    return () => {
      setVIN(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vin]);

  if (!signals || signals.length === 0) return <h3>Loading...</h3>;

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "baseline", gap: "8px" }}>
        <span>Searching every</span>
        <DelayController delays={delays} delayIndex={delayIndex} setDelayIndex={setDelayIndex} />
        {queryDate && <span>for signals sent after {queryDate}.</span>}
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Timestamp</TableCell>
            <TableCell>Signal</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {signals.map((signal, i) => (
            <TableRow key={i}>
              <TableCell>{signal.timestamp}</TableCell>
              <TableCell>{signal.signal}</TableCell>
              <TableCell>{signal.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

const CANSignals = (props) => (
  <CANSignalProvider {...{ token: props.token }}>
    <Main {...props} />
  </CANSignalProvider>
);

const DelayController = ({
  delays,
  delayIndex,
  setDelayIndex
}) => {
  const label = "Delay";
  const handleChange = (event) => {
    setDelayIndex(event.target.value);
  }

  return (
    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
      <InputLabel id="can-signal-delay">{label}</InputLabel>
      <Select
        labelId="can-signal-delay"
        onChange={handleChange}
        value={delayIndex}
        label={label}
      >
        {delays.map((delay, i) => {
          return (
            <MenuItem value={i} selected={i === delayIndex} key={delay}>{delay / 1000} seconds</MenuItem>
          );
        })}
      </Select>
    </FormControl>
  )
}

export default CANSignals;
