import React from "react";
import { Redirect, Route } from "react-router-dom";
import { hasRole } from "../../utils/roles";

export const TYPES = {
  PUBLIC: 0,
  GUEST: 1,
  PROTECTED: 2,
};

export const AuthRoute = ({ token, type, groups, rolesPerGroup, providers, ...others }) => {
  if (type === TYPES.PROTECTED && !token) {
    return <Redirect to="/" />;
  } else if (type === TYPES.GUEST && token) {
    return <Redirect to="/home" />;
  } else if (
    type === TYPES.PROTECTED &&
    token &&
    rolesPerGroup &&
    providers &&
    !hasRole(groups, rolesPerGroup, providers)
  ) {
    return <Redirect to="/home" />;
  }

  return <Route render {...others} />;
};
