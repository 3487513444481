import React from "react";
import ListItemLink from "../ListItemLink";
import ListItemExternalLink from "../ListItemExternalLink"

const MenuItem = ({ item, children }) => {
  return (
    <li>
      {item.label && (<>
        {item.url ?
          <ListItemExternalLink
            primary={item.label}
            url={item.url}
            icon={item.icon}
          />
          : <ListItemLink primary={item.label} to={item.to} icon={item.icon} />}
        {item.component &&
          <item.component />
        }
      </>)}
      {children}
    </li>
  );
};

const ExpandableSideMenuItem = ({ item }) => (
  <>
    <span>
      <MenuItem item={item}></MenuItem>
    </span>
    <ul style={{ marginLeft: 50 }}>
      {item.submenus.map((subitem, index) => (
        <MenuItem key={`submenu-${index}`} item={subitem} />
      ))}
    </ul>
  </>
);

export {ExpandableSideMenuItem, MenuItem}