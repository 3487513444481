import { List } from "@material-ui/core";
import AssessmentIcon from "@material-ui/icons/Assessment";
import BugReportIcon from "@material-ui/icons/BugReport";
import BuildIcon from "@material-ui/icons/Build";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CommuteIcon from "@material-ui/icons/Commute";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import HomeIcon from "@material-ui/icons/Home";
import SettingsInputCompositeIcon from "@material-ui/icons/SettingsInputComposite";
import { default as React, useEffect, useState } from "react";

import { getCustomDashboardSubmenu } from "../../services/customDashboards";
import { hasRole, Permissions } from "../../utils/roles";
import { useUserContext } from "../Contexts/UserContext";
import { ExpandableSideMenuItem, MenuItem } from "./MenuItem";

const menuData = [
  {
    label: "Home",
    to: "/home",
    icon: <HomeIcon />,
    roles: [],
  },
  {
    label: "Deployments",
    to: "/packages",
    icon: <CloudDownloadIcon />,
    rolesPerProvider: Permissions.FiskerMagnaRead,
  },
  {
    label: "Vehicles",
    to: "/vehicles",
    icon: <DirectionsCarIcon />,
    rolesPerProvider: Permissions.FiskerMagnaRead,
  },
  {
    label: "Issues",
    to: "/issues",
    icon: <BugReportIcon />,
    rolesPerProvider: Permissions.FiskerRead,
  },
  {
    label: "Fleets",
    to: "/fleets",
    icon: <CommuteIcon />,
    rolesPerProvider: Permissions.FiskerRead,
  },
  {
    label: "Datascope",
    url: `${process.env.REACT_APP_SUPERSET_URL}/login`,
    icon: <AssessmentIcon />,
    rolesPerProvider: Permissions.FiskerMagnaRead,
    submenus: getCustomDashboardSubmenu(Permissions.FiskerMagnaRead),
  },
  {
    label: "Suppliers",
    to: "/suppliers",
    icon: <SettingsInputCompositeIcon />,
    rolesPerProvider: Permissions.FiskerSupplierAdmin,
  },
  {
    label: "Tools",
    to: "/tools/certificates/add",
    icon: <BuildIcon />,
    rolesPerProvider: Permissions.FiskerTools,
    submenus: [
      ...process.env.REACT_APP_ENV !== "cec-euprd" ? [{
        label: "Certificate",
        to: "/tools/certificates/add",
        rolesPerProvider: Permissions.FiskerMagnaCertificate,
      }] : [],
      {
        label: "Flashpack",
        to: "/tools/flashpacks",
        rolesPerProvider: Permissions.FiskerRead,
      },
      {
        label: "Security.dll",
        to: "/tools/security-dll",
        rolesPerProvider: Permissions.Magna,
      },
      {
        label: "SMS",
        to: "/tools/sms/send",
        rolesPerProvider: Permissions.FiskerCreate,
      },
      {
        label: "SUMS",
        to: "/tools/sumsversions",
        rolesPerProvider: Permissions.FiskerMagnaCreate,
      }
    ],
  },
];

const SideMenu = () => {
  const { groups, providers } = useUserContext();
  const [menu, setMenu] = useState(menuData);

  useEffect(() => {
    filterMenu(groups, providers, setMenu);
  }, [groups, providers]);

  return (
    <List>
      {menu.map((item, index) => {
        const key = `menu-${index}`;
        if (item.submenus)
          return <ExpandableSideMenuItem key={key} item={item} />;
        return <MenuItem key={key} item={item} />;
      })}
    </List>
  );
};

const reduceMenu = (data, groups, providers) => {
  return data.reduce((result, item) => {
    if (hasRole(groups, item.rolesPerProvider, providers)) {
      if (item.submenus) {
        item.submenus = reduceMenu(item.submenus, groups, providers);
      }
      result.push(item);
    }
    return result;
  }, []);
}

const filterMenu = (groups, providers, setMenu) => {
  const filteredMenu = reduceMenu(menuData, groups, providers);
  setMenu(filteredMenu);
};


export default SideMenu;
