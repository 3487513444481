const DEFAULT_GREETING = "Human";

export const parsePayload = (token) => {
  if (!token) return null;
  const parts = token.split(".");
  if (parts.length < 2) return null;
  return JSON.parse(decode(parts[1]));
};

export const decode = (payload) => {
  const l = (payload.length % 4);
  if (l > 0) {
    payload += "=".repeat(4 - l);
  }
  return atob(payload);
};

export const getName = (token) => {
  if (!token || !token.idToken || !token.idToken.jwtToken)
    return DEFAULT_GREETING;

  const payload = parsePayload(token.idToken.jwtToken);

  if (!payload || !payload.given_name) return DEFAULT_GREETING;

  return payload.given_name;
};
