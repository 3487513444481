import { makeStyles } from "@material-ui/core/styles";

const MENUITEM_HEIGHT = 48;
const MENUITEM_PADDING_TOP = 8;
const DRAWER_WIDTH = 240;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  alignCenter: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  modaldialog: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paper: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  paperLeft: {
    alignItems: "flex-start",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    textAlign: "center",
  },
  formControl: {
    margin: theme.spacing(3, 0, 2),
    width: "100%",
    minWidth: 120,
  },
  formControlInline: {
    marginRight: "10px !important",
    minWidth: 120,
  },
  labelInline: {
    fontSize: "1.25em",
    margin: theme.spacing(4, 1, 1),
    display: "inline-flex",
    boxSizing: "border-box",
    verticalAlign: "bottom",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  menuProps: {
    PaperProps: {
      style: {
        maxHeight: MENUITEM_HEIGHT * 4.5 + MENUITEM_PADDING_TOP,
        width: 250,
      },
    },
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210,
  },
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    color: "black",
    backgroundColor: "white",
  },
  appBarShift: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    marginLeft: DRAWER_WIDTH,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  drawerHeaderLogo: {
    backgroundColor: "#3f51b5",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -DRAWER_WIDTH,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    maxWidth: `calc(100% - ${DRAWER_WIDTH}px)`,
  },
  rightToolbar: {
    marginLeft: "auto",
    marginRight: -12,
  },
  link: {
    cursor: "pointer",
    textDecorationColor: "Blue",
    textDecorationStyle: "solid",
    textDecorationLine: "underline",
    color: "Blue",
  },
  hiddenSortSpan: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: "25ch",
  },
  tableToolbar: {
    display: "flex",
    textAlign: "left",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    maxWidth: "100%",
  },
  logo: {
    height: 60,
    width: 60,
    margin: "auto",
  },
  grow: {
    flexGrow: 1,
  },
  iframeResponsive: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
  },
  iframe: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
  },
  embeddedWrapper: {
    marginTop: 10,
    position: "relative",
    overflow: "hidden",
    width: "100%",
    paddingTop: "56.25%",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  homePageTitle: {
    marginBottom: 25,
  },
  markerTitle: {
    margin: 5,
  },
  popUp: {
    minHeight: "15vh",
    maxHeight: "50%",
  },
  popUpTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
  popUpContent: {
    "& p": {
      margin: 0,
    },
    paddingBottom: "2vh",
  },
  popupSection: {
    marginBottom: "1vh",
  },
  toolbarFooter: {
    width: "100%",
    textAlign: "right",
  },
  breadcrumbs: {
    fontSize: "8px",
  },
  addButton: {
    fontSize: "large",
    position: "relative",
    top: 100,
    left: 100,
  },
  batteryGrid: {},
  batteryForm: {
    alignItems: "stretch",
    flexDirection: "column",
  },
  grafanaContainer: {
    alignContent: "stretch",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 15,
    paddingBottom: 20,
  },
  textJustifyAlign: { textAlign: "justify-content" },
  textCenterAlign: { textAlign: "center" },
  textRightAlign: { textAlign: "right" },
  fullWidth: { width: "100%" },
  pageCenter: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  menuExternalLink: {
    textDecoration: "inherit",
    color: "inherit",
  },
  tableSize: {
    width: "100%",
  },
  tabContainer: {
    maxWidth: "100%",
  },
  whiteBackground: { backgroundColor: "White" },
  defaultBackground: { backgroundColor: "#fafafa" },
  progressIcon: { width: 40, height: 40 },
  progressSuccess: { color: "green" },
  progressError: { color: "red" },
  hidden: { display: "none" },
  clickable: { cursor: "pointer" },
  overHighlight: { background: "green" },
  pseudoLink: {
    cursor: "pointer",
    color: "blue",
    textDecoration: "underline",
  },
  tableHeader: {
    textDecorationStyle: "solid",
    fontWeight: 500,
  },
  limitWidthTableCell: {
    maxWidth: "200px",
    whiteSpace: "normal",
    wordWrap: "break-word",
  },
  fleetVehicleAddSubmit: {
    position: "sticky",
    bottom: 0,
    right: 0,
    width: "100%",
    padding: "16px 0",
    backgroundColor: "#fafafa",
  },
  actionsBar: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
  },
  chipList: {
    display: "flex",
    gap: "4px 8px",
    flexWrap: "wrap",
    listStyleType: "none",
    paddingLeft: 0,
  },
  flex: {
    display: "flex",
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
  truncateCell: {
    textOverflow: "ellipsis",
    maxWidth: "200px",
    overflow: "hidden",
    display: "inline-block",
    whiteSpace: "nowrap",
  },
  alignBaseline: {
    display: "flex",
    alignItems: "center",
  },
  formGrid: {
    display: "flex",
    gap: "16px",
    width: "100%",
  },
  formGridItem: {
    flexGrow: 1,
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  marginX: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  closeModal: {
    position: "absolute",
    top: "5px",
    right: "5px",
  },
}));

export default useStyles;
