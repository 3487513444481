import { errorHandler, fetchRespHandler } from "../utils/http";

const AUTH_URL = process.env.REACT_APP_AUTH_SERVICE_URL;
const CALLBACK_URL = process.env.REACT_APP_AUTH_CALLBACK_URL;

const auth = {
  ssoAuthorize: () => `${AUTH_URL}/authorize?redirect=${CALLBACK_URL}`,
  ssoLogout: () => `${AUTH_URL}/logout?redirect=${CALLBACK_URL}`,
  signIn: (code) =>
    fetch(`${AUTH_URL}/token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        code,
        redirect: CALLBACK_URL,
      }),
    })
      .then(fetchRespHandler)
      .catch(errorHandler),

  verify: (idToken) =>
    fetch(`${AUTH_URL}/verify`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: idToken }),
    })
      .then(fetchRespHandler)
      .catch(errorHandler),

  refresh: (refreshToken) =>
    fetch(`${AUTH_URL}/refresh`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refresh_token: refreshToken }),
    })
      .then(fetchRespHandler)
      .catch(errorHandler),
};

export default auth;
