const invalidLocation = 99999;

export const ValidateLocationData = (location) => {
  if (Math.abs(location.latitude) > 90 || Math.abs(location.longitude) > 180) {
    return false;
  }
  if (location.altitude === 1401) {
    return false;
  }
  return true;
}

export const ValidateLocationVehiclePathsData = (location) => {
  if (location.length < 2) {
    return false;
  }

  // location is an array of length 2 as {<latitude> float64, <longitude> float64}
  return !(Math.abs(location[0]) > 90 || Math.abs(location[1]) > 180);
}

export const ValidateLocationByParam = (parameter, value) => {
  if (invalidLocation === value) return false;
  switch (parameter) {
    case "latitude":
      return Math.abs(value) <= 90;
    case "longitude":
      return Math.abs(value) <= 180;
    case "altitude":
      return value !== 1401;
    default:
      return false;
  }
}
