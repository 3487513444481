import { datadogLogs } from "@datadog/browser-logs";

const clientToken = 'pubeb25449bb91773fc993855c7378e375a';
const site = 'datadoghq.com';
const service = 'ota-portal';

datadogLogs.init({
    clientToken,
    site,
    service,
    forwardErrorsToLogs: true,
    sampleRate: 100,
});

const logger = datadogLogs.logger;

export { logger };
