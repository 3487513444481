export const ts2DateTime = (timestamp) => {
  return new Date(timestamp * 1000);
};

export const tsLocalDateTimeString = (timestamp) => {
  const date = ts2DateTime(timestamp);
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};

export const LocalDateTimeString = (datestring) => {
  if (!datestring) return "";

  // adapted from https://www.30secondsofcode.org/js/s/iso-format-date-with-timezone/

  // Pad a number to 2 digits
  const pad = n => `${Math.floor(Math.abs(n))}`.padStart(2, '0');

  // Get timezone offset in ISO format (+hh:mm or -hh:mm)
  const getTzOffset = date => {
    const tzOffset = -date.getTimezoneOffset();
    const diff = tzOffset >= 0 ? '+' : '-';
    return diff + pad(tzOffset / 60) + ':' + pad(tzOffset % 60);
  };

  const toISOStringWithTimezone = date => {
    return pad(date.getMonth() + 1) +
      '/' + pad(date.getDate()) +
      '/' + pad(date.getFullYear()) +
      ' ' + pad(date.getHours()) +
      ':' + pad(date.getMinutes()) +
      ':' + pad(date.getSeconds()) +
      getTzOffset(date);
  };

  return toISOStringWithTimezone(
    new Date(datestring)
  );
};
