import React from "react";
import PropTypes from "prop-types";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "@material-ui/core";

import useStyles from "./useStyles";

function ListItemExternalLink(props) {
  const { icon, primary, url } = props;
  const classes = useStyles();

  return (
    <ListItem
      button
      component={Link}
      href={url}
      rel="noopener"
      target="_blank"
      className={classes.menuExternalLink}
    >
      {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
      <ListItemText primary={primary} />
    </ListItem>
  );
}

ListItemExternalLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default ListItemExternalLink;
