import React, { useState } from "react";
import { Button, Fade, Menu, MenuItem } from "@material-ui/core";

import { useUserContext } from "../../Contexts/UserContext";
import { getName } from "../../../utils/jwt";

const UserMenu = (props) => {
  const { signOut, token } = useUserContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    document.location = signOut();
  };

  return (
    <div {...props}>
      <Button
        aria-controls="fade-menu"
        aria-haspopup="true"
        onClick={handleClick}
        color="inherit"
      >
        {getName(token)}
      </Button>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </Menu>
    </div>
  );
};

export default UserMenu;
