import validator from "email-validator";

export const validateSupplier = (supplier) => {
  if (supplier == null) {
    throw new Error("No supplier data");
  }

  validateEmail(supplier.email);

  if (!supplier?.contact) {
    throw new Error("contact required");
  }

  if (!supplier?.company) {
    throw new Error("company required");
  }

  if (!supplier?.address) {
    throw new Error("address required");
  }

  if (!supplier?.telephone) {
    throw new Error("telephone required");
  }

  if (!supplier?.program) {
    throw new Error("program required");
  }

  if (!supplier?.ecus) {
    throw new Error("ecus required");
  }
};

export const validateEmail = (email) => {
  if (!validator.validate(email)) throw new Error("invalid email");
};

export const validateInterval = (interval) => {
  if (!/^\d+$/.test(interval)) {
    throw new Error("Interval must be number")
  }
}

export const validateEdgeMask = (edgeMask) => {
  if (!/^[a-fA-F0-9]+$/.test(edgeMask)) {
    throw new Error("Edge mask must be hex");
  }
}

export const validateVIN = (vin) => {
  if (vin == null || vin.length !== 17) {
    throw new Error("Invalid VIN");
  }
}

export const validateCANID = (can_id) => {
  if (can_id == null || can_id === "" || !/^\d+(-\d+)?$/.test(can_id)) {
    throw new Error("Invalid CAN ID");
  }
}

export const validateFilter = (filter) => {
  if (filter == null) {
    throw new Error("No filter data");
  }

  validateCANID(filter.can_id)

  const oneOf = (filter.interval || filter.edge_mask) && !(filter.interval && filter.edge_mask)
  if (!oneOf) {
    throw new Error("Only interval or edge mask must be defined")
  }

  if (filter.interval) {
    validateInterval(filter.interval)
    filter.interval = parseInt(filter.interval)
    delete filter.edge_mask;
  }

  if (filter.edge_mask && filter.edge_mask.length > 0) {
    validateEdgeMask(filter.edge_mask)
    delete filter.interval;

  // if the length is odd, add a leading zero
  if (filter.edge_mask.length % 2 === 1) filter.edge_mask = "0"+filter.edge_mask;
  } else {
    filter.edge_mask = undefined;
  }
};
