import VehiclePathsMap from "../components/VehiclePathsMap";

const INVALID_DASHBOARD = {
  label: "Invalid Dashboard",
  error: "Invalid Dashboard"
}

const vinsToShowOnMapColors = new Map([
  ['3FAFP13P71R199267', 'red'],
  ['3FAFP13P71R199270', 'orange'],
  ['3FAFP13P71R199222', 'blue'],
  ['3FAFP13P61R199339', 'yellow'],
  ['3FAFP13P71R199057', 'turquoise'],
  ['3FAFP13P61R199387', 'lime'],
  ['3FAFP13P71R199334', 'purple'],
  ['3FAFP13P71R199284', 'green'],
  ['3FAFP13P71R199303', 'sienna'],
  ['3FAFP13P31R199430', 'navy'],
  ['3FAFP13P81R199083', 'cadetblue'],
  ['3FAFP13P71R199060', 'coral'],
  ['3FAFP13P71R199317', 'darkkhaki'],
  ['3FAFP13P71R199320', 'fuchsia'],
  ['3FAFP13P61R199390', 'indigo'],
  ['3FAFP13P61R199373', 'cyan'],
])
const lookbackHours = 24

export const CustomDashboardList = [
  /*
  {
    label: "IFrame example",
    url: `https://www.fiskerinc.com/page.html`
  },
  {
    label: "Custom Component Example",
    component: <MyCustomComponent />
  }
  */

  {
    label: "Vehicle Map",
    component: <VehiclePathsMap vinsToShowOnMapColors={vinsToShowOnMapColors} lookbackHours={lookbackHours} />
  }
];

export const getCustomDashboard = (index) => {
  if (index < 0 || index >= CustomDashboardList.length) return INVALID_DASHBOARD;
  return CustomDashboardList[index];
};

export const getCustomDashboardSubmenu = (role) => {
  return CustomDashboardList.map((item, index) => ({
    label: item.label,
    to: `/dashboards/${index}`,
    rolesPerProvider: role,
  }));
}
