import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Typography from "@material-ui/core/Typography";

import useStyles from "../useStyles";
import DigitalTwin from "../DigitalTwin";
import CANSignals from "../Cars/CANSignals";
import { useUserContext } from "../Contexts/UserContext";

const VehiclePopUp = (props) => {
  const {
    token: {
      idToken: { jwtToken: token },
    },
  } = useUserContext();
  const classes = useStyles();
  const [viewCAN, setViewCAN] = useState(false);
  const { vin, online, onClose, onlineHMI } = props;

  const toggleView = (e) => {
    e.preventDefault();
    setViewCAN(!viewCAN);
  };

  const close = (e) => {
    setViewCAN(false);
    onClose(e);
  };

  return (
    <Dialog
      fullWidth
      classes={{ paper: classes.popUp }}
      open={true}
      onClose={close}
    >
      <DialogTitle align="center" onClose={onClose}>
        {vin}
        {"  "}
        <IconButton onClick={toggleView}>
          <VisibilityIcon fontSize="inherit" />
        </IconButton>
      </DialogTitle>
      <div align="center" className={classes.popUpContent}>
        <p>
          <b>Connected</b>: {online.toString()}
        </p>
        <p>
          <b>ICC Connected</b>: {onlineHMI?.toString()}
        </p>
        {viewCAN && <CANSignals vin={vin} token={token} />}
        {!viewCAN && <DigitalTwin {...props} />}
      </div>
    </Dialog>
  );
};

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const classes = useStyles();
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.ppopUpTItle}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

export { VehiclePopUp };
