import { parsePayload } from "./jwt";

export const Roles = {
  READ: process.env.REACT_APP_ROLE_READ_ONLY,
  CREATE: process.env.REACT_APP_ROLE_CREATE,
  DELETE: process.env.REACT_APP_ROLE_DELETE,
  CERTIFICATES: process.env.REACT_APP_ROLE_GENERATE_CERTIFICATE,
  APPROVESUPPLIERS: process.env.REACT_APP_ROLE_SUPPLIER_APPROVER,
  UPDATEDEPLOY: process.env.REACT_APP_ROLE_UPDATE_DEPLOY,
  MANUFACTURE: process.env.REACT_APP_ROLE_MANUFACTURE,
  MAGNAGROUP: process.env.REACT_APP_MAGNA_GROUP_ID,
  MANIFEST_MIGRATION: process.env.REACT_APP_ROLE_MANIFEST_MIGRATION,
  CAR_DIAGNOSTIC: process.env.REACT_APP_ROLE_CAR_DIAGNOSTIC
};

export const Providers = {
  FISKER: "Fisker",
  AMERICANLEASE: "AmericanLease",
  FISKER_QA: "Fisker-QA",
  MAGNA: process.env.REACT_APP_MAGNA_PROVIDER,
};

export const getGroups = (token) => {
  const payload = parsePayload(token);

  if (!payload || !payload["custom:groups"]) return null;
  console.log(payload["custom:groups"]);
  return payload["custom:groups"];
};

export const getProviders = (token) => {
  const payload = parsePayload(token);

  if (!payload || !payload["identities"] || payload["identities"].length === 0)
    return null;
  console.log(payload["identities"].map((identity) => identity["providerName"]));
  return payload["identities"].map((identity) => identity["providerName"]);
};

export const hasRole = (userGroups, rolesPerProvider, userProviders) => {
  if (!rolesPerProvider || rolesPerProvider.length === 0) return true;

  if (!userGroups || userGroups.length === 0) return false;

  if (rolesPerProvider && userProviders) {
    for (const provider of userProviders) {
      if (rolesPerProvider[provider]) {
        const rolesForProvider = rolesPerProvider[provider];
        if (!rolesForProvider || rolesForProvider.length === 0) return true;
        if (
          rolesForProvider.filter((role) => userGroups.includes(role)).length >
          0
        ) {
          return true;
        }
      }
    }
  }

  return false;
};


export const Permissions = {
  FiskerRead: {
    [Providers.AMERICANLEASE]: [Roles.CREATE, Roles.READ],
  },
  FiskerCreate: {
    [Providers.AMERICANLEASE]: [Roles.CREATE],
  },
  FiskerDelete: {
    [Providers.AMERICANLEASE]: [Roles.DELETE],
  },
  FiskerSupplierAdmin: {
    [Providers.AMERICANLEASE]: [Roles.APPROVESUPPLIERS],
  },
  FiskerMagnaCertificate: {
    [Providers.AMERICANLEASE]: [Roles.CERTIFICATES],
    [Providers.AMERICANLEASE_QA]: [Roles.MANUFACTURE],
    [Providers.MAGNA]: [Roles.MAGNAGROUP],
  },
  FiskerTools: {
    [Providers.AMERICANLEASE]: [Roles.CERTIFICATES, Roles.CREATE],
    [Providers.AMERICANLEASE_QA]: [Roles.MANUFACTURE],
    [Providers.MAGNA]: [Roles.MAGNAGROUP],
  },
  FiskerUpdateDeploy: {
    [Providers.AMERICANLEASE]: [Roles.UPDATEDEPLOY],
  },
  Magna: {
    [Providers.AMERICANLEASE_QA]: [Roles.MANUFACTURE],
    [Providers.MAGNA]: [Roles.MAGNAGROUP],
  },
  FiskerMagnaRead: {
    [Providers.AMERICANLEASE]: [Roles.CREATE, Roles.READ],
    [Providers.AMERICANLEASE_QA]: [Roles.MANUFACTURE],
    [Providers.MAGNA]: [Roles.MAGNAGROUP],
  },
  FiskerMagnaCreate: {
    [Providers.AMERICANLEASE]: [Roles.CREATE],
    [Providers.AMERICANLEASE_QA]: [Roles.MANUFACTURE],
    [Providers.MAGNA]: [Roles.MAGNAGROUP],
  },
  FiskerMagnaDelete: {
    [Providers.AMERICANLEASE]: [Roles.DELETE],
    [Providers.AMERICANLEASE_QA]: [Roles.MANUFACTURE],
    [Providers.MAGNA]: [Roles.MAGNAGROUP],
  },
  ManifestMigration: {
    [Providers.AMERICANLEASE]: [Roles.MANIFEST_MIGRATION]
  },
  CarDiagnostic: {
    [Providers.AMERICANLEASE]: [Roles.CAR_DIAGNOSTIC],
    [Providers.AMERICANLEASE_QA]: [Roles.CAR_DIAGNOSTIC],
  }
};
