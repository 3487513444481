import React from "react";
import { BrowserRouter } from "react-router-dom";
import { UserProvider } from "../Contexts/UserContext";
import { StatusProvider } from "../Contexts/StatusContext";
import { CssBaseline } from "@material-ui/core";
import MenuDrawer from "../Layouts/MenuDrawer";
import SiteRoutes from "../Routes/SiteRoutes";
import { } from "../../services/monitoring";

function App() {
  return (
    <StatusProvider>
      <UserProvider>
        <CssBaseline />
        <BrowserRouter>
          <MenuDrawer>
            <SiteRoutes />
          </MenuDrawer>
        </BrowserRouter>
      </UserProvider>
    </StatusProvider>
  );
}

export default App;
