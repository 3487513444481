import React, { Suspense } from "react";
import { Switch } from "react-router-dom";

import { Permissions } from "../../utils/roles";
import { useUserContext } from "../Contexts/UserContext";
import { MessageBar } from "../MessageBar";
import { AuthRoute, TYPES } from "../Routes/AuthRoute";

const CANFilterCreate = React.lazy(() => import("../CANFilter/Add"));
const CANFilterUpdate = React.lazy(() => import("../CANFilter/Update"));
const IssuesList = React.lazy(() => import("../Issues/List"))
const IssueInfo = React.lazy(() => import("../Issues/Info"))
const CarsList = React.lazy(() => import("../Cars/List"));
const CarStatus = React.lazy(() => import("../Cars/Status"));
const CarUpdateStatus = React.lazy(() => import("../Cars/UpdateStatus"));
const FleetsList = React.lazy(() => import("../Fleets/Table"));
const FleetStatus = React.lazy(() => import("../Fleets/Status"));
const FleetAddForm = React.lazy(() => import("../Fleets/Add"));
const FleetUpdateForm = React.lazy(() => import("../Fleets/Update"));
const FleetAddVehicleForm = React.lazy(() => import("../Fleets/Status/Vehicles/Add"));
const FleetAddCANFilterForm = React.lazy(() => import("../Fleets/Status/CANFilters/Add"));
const FleetUpdateCANFilterForm = React.lazy(() => import("../Fleets/Status/CANFilters/Update"));
const Home = React.lazy(() => import("../Home"));
const Manifests = React.lazy(() => import("../Manifest/List"));
const ManifestDeploy = React.lazy(() => import("../Manifest/Deploy"));
const ManifestStatus = React.lazy(() => import("../Manifest/Status"));
const ManifestUpdate = React.lazy(() => import("../Manifest/Update"));
const PageNotFound = React.lazy(() => import("../404"));
const SSOForm = React.lazy(() => import("../SSOForm"));
const VehicleAddForm = React.lazy(() => import("../Cars/Add"));
const VehicleUpdateForm = React.lazy(() => import("../Cars/Update"));
const CertificateCreate = React.lazy(() => import("../Certificates/Add"));
const SecurityDLL = React.lazy(() => import("../Magna/SecurityDLL"));
const SMSSend = React.lazy(() => import("../SMS/Send"));
const SuppliersList = React.lazy(() => import("../Suppliers/List"));
const SupplierDetails = React.lazy(() => import("../Suppliers/Details"));
const Flashpacks = React.lazy(() => import("../Flashpack"));
const FlashpackDetails = React.lazy(() => import("../Flashpack/Details"));
const FlashpackECUMappingAdd = React.lazy(() => import("../Flashpack/AddMapping"))
const FlashpackAdd = React.lazy(() => import("../Flashpack/Add"))
const Datascope = React.lazy(() => import("../Dashboard"));
const Sums = React.lazy(() => import("../SUMS/List"))
const SumsNew = React.lazy(() => import("../SUMS/New"))
const SumsRxSwin = React.lazy(() => import("../SUMS"));
const SumsRxSwinAdd = React.lazy(() => import("../SUMS/Add"));
const DashboardCustom = React.lazy(() => import("../DashboardCustom"));

const SiteRoutes = () => {
  const { token, groups, providers } = useUserContext();
  return (
    <Suspense fallback={"Loading..."}>
      <MessageBar />
      <Switch>
        <AuthRoute
          path="/"
          exact
          render={() => <SSOForm />}
          type={TYPES.GUEST}
          token={token}
        />
        <AuthRoute
          path="/dashboards/:index"
          render={() => <DashboardCustom />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          rolesPerGroup={Permissions.FiskerMagnaRead}
          providers={providers}
        />
        <AuthRoute
          path="/filter-add"
          render={() => <CANFilterCreate />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          rolesPerGroup={Permissions.FiskerCreate}
          providers={providers}
        />
        <AuthRoute
          path="/filter-update"
          render={() => <CANFilterUpdate />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          rolesPerGroup={Permissions.FiskerCreate}
          providers={providers}
        />
        <AuthRoute
          path="/fleets"
          render={() => <FleetsList />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          rolesPerGroup={Permissions.FiskerRead}
          providers={providers}
        />
        <AuthRoute
          path="/fleet/:name/vehicle-add"
          render={() => <FleetAddVehicleForm />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          rolesPerGroup={Permissions.FiskerCreate}
          providers={providers}
        />
        <AuthRoute
          path="/fleet/:name/filter-add"
          render={() => <FleetAddCANFilterForm />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          rolesPerGroup={Permissions.FiskerCreate}
          providers={providers}
        />
        <AuthRoute
          path="/fleet/:name/filter-update"
          render={() => <FleetUpdateCANFilterForm />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          rolesPerGroup={Permissions.FiskerCreate}
          providers={providers}
        />
        <AuthRoute
          path="/fleet/:name"
          render={() => <FleetStatus />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          rolesPerGroup={Permissions.FiskerRead}
          providers={providers}
        />
        <AuthRoute
          path="/fleet-add"
          render={() => <FleetAddForm />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          rolesPerGroup={Permissions.FiskerCreate}
          providers={providers}
        />
        <AuthRoute
          path="/fleet-update"
          render={() => <FleetUpdateForm />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          rolesPerGroup={Permissions.FiskerCreate}
          providers={providers}
        />
        <AuthRoute
          path="/home"
          render={() => <Home />}
          type={TYPES.PROTECTED}
          token={token}
        />
        <AuthRoute
          path="/packages"
          render={() => <Manifests />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          rolesPerGroup={Permissions.FiskerMagnaRead}
          providers={providers}
        />
        <AuthRoute
          path="/package-deploy/:manifest_id"
          render={() => <ManifestDeploy />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          rolesPerGroup={Permissions.FiskerMagnaCreate}
          providers={providers}
        />
        <AuthRoute
          path="/package-status/:manifest_id"
          render={() => <ManifestStatus />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          rolesPerGroup={Permissions.FiskerMagnaRead}
          providers={providers}
        />
        <AuthRoute
          path="/package-update/:manifest_id"
          render={() => <ManifestUpdate />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          rolesPerGroup={Permissions.FiskerRead}
          providers={providers}
        />
        <AuthRoute
          path="/issues"
          render={() => <IssuesList />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          rolesPerGroup={Permissions.FiskerRead}
          providers={providers}
        />
        <AuthRoute
          path="/issue-info/:id"
          render={() => <IssueInfo />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          rolesPerGroup={Permissions.FiskerRead}
          providers={providers}
        />
        <AuthRoute
          path="/vehicles"
          render={() => <CarsList />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          rolesPerGroup={Permissions.FiskerMagnaRead}
          providers={providers}
        />
        <AuthRoute
          path="/vehicle-add"
          render={() => <VehicleAddForm />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          rolesPerGroup={Permissions.FiskerCreate}
          providers={providers}
        />
        <AuthRoute
          path="/vehicle-status/:vin/:carupdateid"
          render={() => <CarUpdateStatus />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          rolesPerGroup={Permissions.FiskerMagnaRead}
          providers={providers}
        />
        <AuthRoute
          path="/vehicle-status/:vin"
          render={() => <CarStatus />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          rolesPerGroup={Permissions.FiskerMagnaRead}
          providers={providers}
        />
        <AuthRoute
          path="/vehicle-update"
          render={() => <VehicleUpdateForm />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          rolesPerGroup={Permissions.FiskerMagnaCreate}
          providers={providers}
        />
        <AuthRoute
          path="/tools/certificates/add"
          render={() => <CertificateCreate />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          rolesPerGroup={Permissions.FiskerMagnaCertificate}
          providers={providers}
        />
        <AuthRoute
          path="/tools/security-dll"
          render={() => <SecurityDLL />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          rolesPerGroup={Permissions.Magna}
          providers={providers}
        />
        <AuthRoute
          path="/tools/sms/send"
          render={() => <SMSSend />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          rolesPerGroup={Permissions.FiskerCreate}
          providers={providers}
        />
        <AuthRoute
          path="/tools/flashpacks"
          render={() => <Flashpacks />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          rolesPerGroup={Permissions.FiskerRead}
          providers={providers}
        />
        <AuthRoute
          path="/tools/flashpack/:model/:trim/:year/:flashpack"
          render={() => <FlashpackDetails />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          rolesPerGroup={Permissions.FiskerRead}
          providers={providers}
        />
        <AuthRoute
          path="/tools/flashpackadd/:model/:trim/:year/:flashpack"
          render={() => <FlashpackECUMappingAdd />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          rolesPerGroup={Permissions.FiskerCreate}
          providers={providers}
        />
        <AuthRoute
          path="/tools/flashpack/add"
          render={() => <FlashpackAdd />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          rolesPerGroup={Permissions.FiskerCreate}
          providers={providers}
        />
        <AuthRoute
          path="/suppliers"
          render={() => <SuppliersList />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          rolesPerGroup={Permissions.FiskerSupplierAdmin}
          providers={providers}
        />
        <AuthRoute
          path="/supplier/:email"
          render={() => <SupplierDetails />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          rolesPerGroup={Permissions.FiskerSupplierAdmin}
          providers={providers}
        />
        <AuthRoute
          path="/datascope"
          render={() => <Datascope />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          rolesPerGroup={Permissions.FiskerMagnaRead}
          providers={providers}
        />
        <AuthRoute
          path="/tools/sumsversions"
          render={() => <Sums />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          rolesPerGroup={Permissions.FiskerMagnaRead}
          providers={providers}
        />
        <AuthRoute
          path="/tools/sums/new"
          render={() => <SumsNew />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          rolesPerGroup={Permissions.FiskerMagnaCreate}
          providers={providers}
        />
        <AuthRoute
          path="/tools/sums/:sums_version"
          render={() => <SumsRxSwin />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          rolesPerGroup={Permissions.FiskerMagnaCreate}
          providers={providers}
        />
        <AuthRoute
          path="/tools/sums-rxswin-add/:sums_version"
          render={() => <SumsRxSwinAdd />}
          type={TYPES.PROTECTED}
          token={token}
          groups={groups}
          rolesPerGroup={Permissions.FiskerMagnaCreate}
          providers={providers}
        />
        <PageNotFound />
      </Switch>
    </Suspense>
  );
};

export default SiteRoutes;
