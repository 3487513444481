import worker_script from "./timeoutScript";

const getTimerWorker = () => {
  const worker = new Worker(worker_script);
  let messageHandler = null;
  const workerHandler = (e) => {
    if (messageHandler === null) return;
    messageHandler(e);
  }

  worker.addEventListener("message", workerHandler);

  return {
    start: (duration) => {
      if (!worker) return;
      worker.postMessage({
        action: "start",
        duration,
      });
    },

    stop: () => {
      worker.postMessage({
        action: "stop",
      });
    },

    onMessage: (handler) => {
      messageHandler = handler;
    },

    terminate: () => {
      worker.removeEventListener("message", workerHandler);
      worker.terminate();
      messageHandler = null;
    }
  }
}

export default getTimerWorker;
