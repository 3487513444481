import React, { useContext, useState } from "react";

const StatusContext = React.createContext();

export const StatusProvider = ({ children }) => {
  const [message, setMessage] = useState(null);
  const [title, setTitle] = useState("");
  const [sitePath, setSitePath] = useState([]);

  return (
    <StatusContext.Provider
      value={{
        message,
        title,
        sitePath,
        setMessage,
        setTitle,
        setSitePath,
      }}
    >
      {children}
    </StatusContext.Provider>
  );
};

export const useStatusContext = () => useContext(StatusContext);
